.azuremediaplayer.vjs-fullscreen .outline-enabled.vjs-player:focus .vjs-fullscreen-outline {
  border: none;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: block;
}
.amp-custom-skin {
  .vjs-lock-showing {
    display: inline !important;
    opacity: 1 !important;
    visibility: visible !important;
  }

  .vjs-button-tooltip  {
    display: none !important;
  }
  .vjs-captions-button:focus .vjs-control-content::before,
  .vjs-captions-button:hover .vjs-control-content::before {
    box-shadow: none;
    font-size: 9.3px;
  }

  .vjs-control,
  .vjs-control:hover,
  .vjs-control[aria-expanded="true"] {
    background-color: transparent;
  }
  .outline-enabled .outline-enabled-control:focus {
    outline: none !important;
  }
  .outline-enabled .outline-enabled-control:focus {
    outline: none !important;
  }
  .vjs-control-bar {
    padding: 0.4rem 0;
    color: white !important;

    .vjs-play-control {
      margin-left: 8px;
      color: white !important;
    }

    .vjs-mute-control {
      color: white !important;
    }
    .vjs-menu-button {
      color: white !important;
    }

    .vjs-fullscreen-control {
      color: white !important;
      margin-right: 8px;
    }

    .vjs-control.amp-control-disabled {
      width: 0 !important;
      height: 0 !important;
    }

    @media screen and (min-width: 587px) {
      .vjs-menu-button {
        margin-right: 24px;
      }
      .vjs-volume-control {
        margin-right: 24px;
      }

      .vjs-current-time.vjs-time-control {
        margin-left: 6px;
      }
      .vjs-current-time.vjs-time-control,
      .vjs-time-control.vjs-time-divider,
      .vjs-duration.vjs-time-control {
        font-size: 0.8rem;
        line-height: 1.8rem;
        height: 2rem;
      }

      .vjs-control.vjs-button {
        height: 2.2rem;
        width: 2.2rem;
      }
      .vjs-control.vjs-button {
        height: 2.2rem;
        width: 2.2rem;
      }
      .vjs-control::before {
        height: 2rem;
        width: 2.2rem;
        font-family: azuremediaplayer;
        font-size: 2rem;
        line-height: 2.2rem;
      }
    }

    @media screen and (min-width: 768px) {
      .vjs-volume-control .vjs-menu {
        bottom: 40px;
      }
      .vjs-menu-button {
        margin-right: 40px;
      }
      .vjs-volume-control {
        margin-right: 40px;
      }

      .vjs-current-time.vjs-time-control {
        margin-left: 20px;
      }
      .vjs-current-time.vjs-time-control,
      .vjs-time-control.vjs-time-divider,
      .vjs-duration.vjs-time-control {
        font-size: 1rem;
        line-height: 2.6rem;
        height: 2.8rem;
      }
      .vjs-control.vjs-button {
        height: 3rem;
        width: 3rem;
      }
      .vjs-control::before {
        height: 3rem;
        width: 3rem;
        font-family: azuremediaplayer;
        font-size: 2.75rem;
        line-height: 3rem;
      }
    }

    .vjs-time-control {
      color: white !important;
      position: inherit !important;
      display: inline-flex !important;
    }

    .vjs-time-divider {
      margin: 0 5px !important;
    }

    .vjs-remaining-time {
      display: none !important;
    }
    .amp-controlbaricons-middle {
      color: white !important;

      .vjs-time-control {
        color: white !important;
      }
    }
  }
}
@media screen and (orientation: landscape) {
  .amp-custom-skin.vjs-fullscreen {
    .vjs-control-bar {
      .vjs-current-time.vjs-time-control,
      .vjs-time-control.vjs-time-divider,
      .vjs-duration.vjs-time-control {
        font-size: 1rem;
        line-height: 2.6rem;
        height: 2.8rem;
      }
      .vjs-control.vjs-button {
        height: 3rem;
        width: 3rem;
      }
      .vjs-control.amp-control-disabled {
        width: 0;
        height: 0;
      }
      .vjs-control::before {
        height: 3rem;
        width: 3rem;
        font-family: azuremediaplayer;
        font-size: 2.75rem;
        line-height: 3rem;
      }

      .vjs-time-control {
        color: white !important;
        position: inherit !important;
        display: inline-flex !important;
      }

      .vjs-time-divider {
        margin: 0 5px !important;
      }

      .vjs-remaining-time {
        display: none !important;
      }
    }
  }
}
.amp-custom-skin:hover .vjs-progress-control {
  background-color: transparent;
  height: 4px;
}
