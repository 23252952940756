// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

@import "./styles/var";
@import "./styles/media-player";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$future-females-typography: mat.define-typography-config(
  $font-family: "Montserrat",
  $display-4: mat.define-typography-level(112px, 112px, 300, "Montserrat Light", -0.0134em),
  $display-3: mat.define-typography-level(72px, 86px, 400, "Montserrat", -0.0089em),
  $display-2: mat.define-typography-level(40px, 48px, 600, "Montserrat", 0em),
  $display-1: mat.define-typography-level(34px, 40px, 400, "Montserrat", 0.0074em),
  $headline: mat.define-typography-level(24px, 28px, 400, "Montserrat", 0em),
  $title: mat.define-typography-level(20px, 32px, 500, "Montserrat", 0.0075em),
  $subheading-2: mat.define-typography-level(16px, 25px, 400, "Montserrat", 0.0094em),
  $subheading-1: mat.define-typography-level(15px, 24px, 500, "Montserrat", 0.0067em),
  $body-2: mat.define-typography-level(14px, 24px, 600, "Montserrat", 0.0179em),
  $body-1: mat.define-typography-level(14px, 25px, 400, "Montserrat", 0.0179em),
  $button: mat.define-typography-level(12px, 12px, 600, "Montserrat", 0.0893em),
  $caption: mat.define-typography-level(12px, 20px, 400, "Montserrat", 0.0333em),
  $input: mat.define-typography-level(inherit, 1.125, 400, "Montserrat", 1.5px),
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$future-females-web-theme: mat.define-dark-theme(
  (
    color: $dynamic-app-theme,
    typography: $future-females-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($future-females-web-theme);
@include angular-material-typography($future-females-typography);

html {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Montserrat", "Helvetica Neue", sans-serif;
  height: 100%;
  padding: 0;
}
body.mat-app-background {
  background-color: var(--theme-primary-500);
  color: var(--theme-primary-contrast-500);
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

b {
  font-family: "Montserrat";
  font-weight: 700;
}

.pointer {
  cursor: pointer;
}

.primary {
  background-color: var(--theme-primary-500);
  color: #ffffff;
}

.primary-text {
  color: var(--theme-primary-500);
}

.accent {
  background-color: var(--theme-accent-500);
  color: #ffffff;
}

.accent-text {
  color: var(--theme-accent-500);
}

.light-opacity {
  opacity: 0.4;
}

button {
  text-transform: uppercase;
}
button.ff-base-button {
  color: var(--theme-accent-500);
  background-color: white;
}

.notification-service-snackbar-error {
  background-color: red;
  color: white;
}
.notification-service-snackbar-warn {
  background-color: rgb(253, 135, 0);
  color: white;
}
.notification-service-snackbar-info {
  @extend .accent;
}

.app-xxl-container {
  margin: 0 auto;
  width: 90%;
  max-width: 1440px;
  padding: 0;
}

.container-loading {
  height: 100vh;
}

@media screen and (max-width: 576px) {
  .app-xxl-container {
    width: 100%;
  }

  * {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  *::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.app-xl-container {
  margin: 0 auto;
  width: 90%;
  max-width: 1200px;
  padding: 0 16px;
}

@media screen and (max-width: 992px) {
  .app-xl-container {
    width: 100%;
  }

  * {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  *::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.app-content-large-container {
  margin: 0 auto;
  width: 90%;
  max-width: 1000px;
}

.app-page-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 48px;
  line-height: 100%;
}

.h-100 {
  height: 100%;
}

.w-50 {
  width: 50%;
}

.min-h-100 {
  min-height: 100%;
}

button.no-shadow {
  box-shadow: none;
}

.profile-picture {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.small-icon {
  font-size: 12px;
  width: 12px;
  height: 12px;
}

.text-bold {
  font-family: "Montserrat";
  font-weight: 600;
}

.text-gray {
  color: rgba(255, 255, 255, 0.4);
}

.mt-48px {
  margin-top: 48px;
}

.mt-96px {
  margin-top: 96px;
}

.topic-pill {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  font-weight: 600;
  line-height: 100%;
}
.topic-pill.list {
  padding: 10px;
  font-size: 16px;
  height: fit-content;
}
.topic-pill.page {
  padding: 8px;
  font-size: 12px;
  height: fit-content;
}
.topic-pill-gap {
  gap: 5px;
}

.mat-expansion-panel-header.height-100 {
  .mat-content {
    height: 100%;
  }
}

.mat-form-field.mat-default {
  .mat-input-element {
    caret-color: rgba(255, 255, 255, 0.7);
  }

  &.mat-focused .mat-form-field-label {
    color: rgba(255, 255, 255, 0.7);
  }
}

.event-overlay {
  flex-grow: 1;
  flex-shrink: 0;
  width: 200px;
}

.going-overlay {
  flex-grow: 1;
  flex-shrink: 0;
  width: 250px;
}

.mat-progress-bar.mat-accent.green .mat-progress-bar-fill::after {
  background-color: #5db749;
}

button.mat-flat-button {
  border-radius: 4px !important;
}
button.mat-button {
  border-radius: 4px !important;
}
button.mat-raised-button.white {
  color: black;
  background-color: white;
}
button.mat-stroked-button.white {
  color: black;
  border: 1px solid #d4d4d4;
  background-color: white;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 10px !important;
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field-wrapper {
  padding: 0;
}

.mat-progress-bar.bg-contrast .mat-progress-bar-buffer {
  background-color: var(--theme-accent-contrast-50);
}
.mat-progress-bar.bg-contrast .mat-progress-bar-background {
  fill: var(--theme-accent-contrast-50);
}

div.mat-menu-panel {
  max-width: 400px;
}

mat-tab-group.center .mat-tab-labels {
  justify-content: center;
}

.card-container {
  margin-top: 40px;
  background-color: var(--theme-primary-300);
  border-radius: 4px;
  padding: 30px;
}

.mat-form-field-appearance-fill.dark {
  .mat-form-field-flex {
    background-color: var(--theme-accent-contrast-50);
  }

  &:not(.mat-form-field-disabled) {
    label {
      color: white;
    }

    input::placeholder {
      color: white;
    }

    .mat-select-placeholder {
      color: white;
    }
  }

  &.mat-form-field-disabled {
    label {
      color: rgba(255, 255, 255, 0.5);
    }

    input::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }

    .mat-select-placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.mat-raised-button-white {
  color: var(--theme-accent-contrast-50);
  background-color: white;
}

.mat-form-field.clean {
  .mat-form-field-flex {
    padding: 0;
    background: none;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-infix {
    width: 100%;
  }
}

.mat-form-field.border-accent {
  .mat-form-field-flex {
    border: 1px solid transparent;
  }

  &.mat-focused {
    .mat-form-field-flex {
      border: 1px solid var(--theme-accent-500);
    }
  }
}

.mat-tab-label {
  color: rgba(255, 255, 255, 0.54) !important;
}
.mat-tab-label-active {
  color: white !important;
}

.mat-ink-bar {
  background-color: white !important;
}

.share-buttons-container,
.events-share-container,
.going-share-container {
  background: #faf9f8;
  padding: 15px 20px;
  position: relative;
  border-radius: 5px;
  margin: 15px 0;
  cursor: pointer;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: baseline;

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    color: black;
    cursor: pointer;
  }
}

.going-share-container {
  color: black;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  width: 100%;

  .mat-radio-outer-circle {
    border-color: black;
  }

  .mat-radio-button {
    width: 100%;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  .mat-radio-label {
    justify-content: space-between;
  }

  .mat-radio-label-content {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
  }

  .mat-radio-checked {
    .circle-icon-container {
      background: rgb(255, 55, 135, 0.15);
    }

    .mat-icon {
      filter: invert(38%) sepia(36%) saturate(5852%) hue-rotate(315deg) brightness(101%) contrast(101%);
    }
  }

  .circle-icon-container {
    width: 32px;
    height: 32px;
    display: inline-flex;
    background: rgba(0, 0, 0, 0.15);
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 25px;
    margin-right: 5px;
  }
}

@media screen and (max-width: 768px) {
  .user-actions {
    display: flex;
  }
}

.events-share-container {
  .material-symbols-outlined {
    color: black;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
  }
}

.phone-confirm-modal {
  .mat-dialog-container {
    background: white;
    width: 450px;
    max-width: 100vw;
    border-radius: 20px;
  }
}

code-input {
  --item-height: 1.5em !important;
  font-size: 48px !important;
  color: rgba(0, 0, 0, 0.2) !important;
}

.credit-card-modal {
  .mat-dialog-container {
    background: #262626;
    position: relative;
    border-radius: 4px;

    width: 500px;
    // min-width: 500px;
    max-width: 100vw;

    label {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: white;
    }

    --fontSizeSm: 16px !important;
  }
}

.course-preview-dialog-container .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}

.mat-tab-header-pagination-controls-enabled .mat-tab-label-container {
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  .mat-tab-list {
    transform: none !important;
  }
}

.app-header {
  .mat-tab-header {
    border-top: 1px solid rgba(255, 255, 255, 0.12);

    .mat-tab-labels {
      justify-content: center;
    }
  }
}

.highlightRecord {
  .mat-tab-label {
    opacity: 1;

    &:nth-child(2) {
      .mat-tab-label-content {
        color: var(--theme-accent-500);
      }
    }
  }
}

.text-accent {
  color: #fe5492;
  opacity: 1;
}

[arrowscroll] {
  button.next,
  button.prev {
    position: absolute;
    top: calc(50% - 15px);
    height: 30px;
    width: 30px;
    border-radius: 30px;
    outline: none;
    border: none;
    background: white;
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;

    &.onHover {
      &:not(.hovered) {
        display: none !important;
      }
    }
  }

  button.prev {
    left: -15px;
  }

  button.next {
    right: -15px;
  }

  .hidden {
    display: none !important;
  }

  @media screen and (max-width: 700px) {
    button.prev {
      left: 3px;
    }
    button.next {
      right: 3px;
    }
  }
}

.ff-fab-button {
  border-radius: 50%;
  background-color: white;
  color: var(--theme-primary-500);
}

.ff-fab-button.selected {
  border-radius: 50%;
  background-color: white;
  color: var(--theme-accent-500);
}

.validation-error {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  text-align: center;

  color: #ff5660;

  &.align-left {
    text-align: left;
  }
}

.cdk-overlay-connected-position-bounding-box:has(.header-menu) {
  right: 0 !important;
  top: 0 !important;

  .mat-menu-panel {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

.mat-tab-header {
  z-index: 0;
}

.cc-window {
  padding-right: 8px !important;
  padding-left: 32px !important;

  .cc-revoke {
    display: none !important;
  }

  .cc-message {
    font-family: Montserrat;
    font-size: 14px;
  }

  .cc-compliance {
    .cc-btn {
      box-sizing: border-box;
      position: relative;
      -webkit-user-select: none;
      user-select: none;
      cursor: pointer;
      outline: none;
      border: none;
      -webkit-tap-highlight-color: transparent;
      display: inline-block;
      white-space: nowrap;
      text-decoration: none;
      vertical-align: baseline;
      text-align: center;
      margin: 0;
      min-width: 64px !important;
      line-height: 36px;
      padding: 0 16px;
      border-radius: 4px;
      overflow: visible;

      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      text-decoration: none;

      &:hover {
        background-color: #ff3787;
      }
    }
  }
}

#notification-drawer {
  z-index: 2;

  .mat-drawer-backdrop {
    opacity: 0;
    position: fixed;
  }
}

.cdk-overlay-pane.member-dialog {
  @media screen and (max-width: 476px) {
    max-width: 100vw !important;
    width: 100vw;
    height: 100vh;
    background: #faf9f8;
  }
  mat-dialog-container {
    transform: none;
    padding: 0;
    box-shadow: none;
    background: none;
  }
}

@font-face {
  font-family: "icomoon";
  src: url("assets/fonts/icomoon.eot?s3p8z9");
  src: url("assets/fonts/icomoon.eot?s3p8z9#iefix") format("embedded-opentype"),
    url("assets/fonts/icomoon.ttf?s3p8z9") format("truetype"), url("assets/fonts/icomoon.woff?s3p8z9") format("woff"),
    url("assets/fonts/icomoon.svg?s3p8z9#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
